// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/search.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/edit_pencil.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/svg/close_cross.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/svg/arrow-down.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/info.png", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/svg/check.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./contacts.scss"></require>
  <require from="../../components/max-length"></require>
  <require from="../../components/ValueConverters/iban-converter"></require>
  <require from="../../components/banka-contact/banka-contact"></require>
  <require from="../../components/header-titles/header-title-contacts/header-title-contacts"></require>  
  <require from="./add/add"></require> 
  <require from="./accept/accept"></require>
  <section id="contacts" class="flex-column">
    <header-title-contact title.bind="pageTitle" subtitle.bind="subtitle" menu-items.bind="listMenuItems" is-mk.bind="state.makePayment.isMK" search-term.two-way="search_term" is-editing.two-way="isEditing">
    </header-title-contact>
    <form class="search_form">
      <div class="search_field">
        <img class="loupe" src="${___HTML_LOADER_IMPORT_0___}">
        <div class="flex-grow"></div>
        <input id="search-input-1" type="text" value.bind="search_term" t="[placeholder]contacts.recherche_add" onkeydown="return (event.keyCode!=13);" autofocus>
        <div class="flex-grow"></div>
      </div>
    </form>

    <div class="view_container flex-row">
      <div class="scrollable left flex-column" class.bind="isContactsPage ? '': 'hidden_on_mobile'">
        <!-- All contacts tab -->
        <div class="contacts_list" if.bind="tabs == 0">
          <div class="contacts">
            <h4 t="profile.choose.my_account" if.bind="state.wallets.length > 0 && state.makePayment.isMK"></h4>
            <div class="user_info" repeat.for="wallet of state.wallets"
              click.delegate="setPaymentRecipient(wallet)" if.bind="state.wallets.length > 0 && state.makePayment.isMK">
              <img src.bind="wallet.isBlockchain ? '/img/currency/bitcoin/bitcoin_account_white.png' : '/img/bank_account_white.png'">
              <div class="name_account_container flex-column">
                <span class="name">\${wallet.name}</span>
                <span class="identification">\${wallet.identification}</span>
              </div>
            </div>
            <h4 t="profile.choose.choose_contacts" if.bind="myContacts && state.wallets.length > 0 && state.makePayment.isMK && search_results_contacts">My contacts</h4>

            <div class="user_info \${(isExtended && selectedContactForDetails.id === contact.id && contact.accounts.length > 1) ? 'extended' : 'non_extended'}" repeat.for="contact of search_results_contacts"
              click.delegate="goToContactDetails(contact)" if.bind="search_results_contacts">
              <div class="user_info_container">
                <img src.bind="contact.urlPic">
                <div class="name_account_container flex-column">
                  <span class="name">\${contact.name}</span>
                  <span class="identification" if.bind="contact.accounts.length < 2">\${contact.accounts[0].identification}</span>
                  <div class="span_container flex-row" style="gap: 0.5rem;" if.bind="contact.accounts.length > 1">
                    <span class="identification" >\${contact.accounts.length}</span>
                    <span class="identification" t="contacts.accounts"></span>
                  </div>
                </div>
                <div class="flex-grow" if.bind="isEditing"></div> 
                <img id="edit_pencil" class="action" if.bind="isEditing && contact.email" src="${___HTML_LOADER_IMPORT_1___}" click.delegate="acceptFriendRequest(contact)"/>
                <img id="close_cross" class="action" if.bind="isEditing" src="${___HTML_LOADER_IMPORT_2___}" click.delegate="deleteFriendRequest(contact)"/>
                <div class="flex-grow" if.bind="contact.accounts.length > 1 && !isEditing"></div>
                <img class="arrow_down \${(isExtended && selectedContactForDetails.id === contact.id) ? 'rotate' : 'non_rotate'}" src="${___HTML_LOADER_IMPORT_3___}" if.bind="contact.accounts.length > 1 && !isEditing"/>
              </div>
              <div class="accounts_container flex-row user_info_container" repeat.for="account of contact.accounts" click.delegate="selectAccount(account)">
                <img src.bind="account.identification.includes('@') ? '/img/currency/bitcoin/bitcoin_account_white.png' : '/img/bank_account_white.png'">
                <div class="name_account_container flex-column">
                  <span class="name">\${account.name}</span>
                  <span class="identification">\${account.identification}</span>
                </div>
              </div>
            </div>

            <!--No contact card-->
            <div id="no-contact-card" class="user_info" if.bind="myContacts.length < 1" click.delegate="goToAdd()">
              <div class="user_info_container">
                <img src="${___HTML_LOADER_IMPORT_4___}">
                <div class="name_account_container flex-column">
                  <span class="name" t="contacts.contacts_select_no_friends_yet"></span>
                </div>
              </div>
            </div>
            <div id="no-contact-card" class="user_info" if.bind="search_results_contacts.length < 1 && myContacts.length > 0" click.delegate="goToAdd()">
              <div class="user_info_container">
                <img src="${___HTML_LOADER_IMPORT_4___}">
                <div class="name_account_container flex-column">
                  <span class="name" t="contacts.contacts_no_accounts_found">No search result</span>
                  <span class="name blue" t="contacts.contacts1" click.delegate="goToAdd()">want to add?</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Received invitation tab -->
        <div class="contacts_list" if.bind="tabs == 1">
          <div class="contacts">
            <div class="user_info" repeat.for="contact of mySentFriendsRequests">
              <div class="user_info_container">
                <img src.bind="contact.urlPic">
                <div class="name_account_container flex-column">
                  <span class="name">\${contact.name}</span>
                  <span class="identification">\${contact.email}</span>
                </div>
                <div class="flex-grow"></div>
                <img id="check_received" class="action" src="${___HTML_LOADER_IMPORT_5___}" click.delegate="acceptFriendRequest(contact)"/>
                <img id="close_cross_received" class="action" src="${___HTML_LOADER_IMPORT_2___}" click.delegate="deleteFriendRequest(contact)"/>
              </div>
            </div>
            <div class="user_info" if.bind="mySentFriendsRequests.length < 1" click.delegate="goToAdd()">
              <div class="user_info_container">
                <img src="${___HTML_LOADER_IMPORT_4___}">
                <div class="name_account_container flex-column">
                  <span class="name" t="contacts.contacts_select_no_friends_yet"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- sent invitation tab -->
        <div class="contacts_list" if.bind="tabs == 2">
          <div class="contacts">
            <div class="user_info" repeat.for="contact of myReceivedFriendsRequests">
              <div class="user_info_container">
                <img src.bind="contact.urlPic">
                <div class="name_account_container flex-column">
                  <span class="name">\${contact.name}</span>
                  <span class="identification">\${contact.email}</span>
                </div>
                <div class="flex-grow"></div>
                <img class="action" src="${___HTML_LOADER_IMPORT_2___}" click.delegate="deleteFriendRequest(contact)"/>
              </div>
            </div>
            <div class="user_info" if.bind="myReceivedFriendsRequests.length < 1" click.delegate="goToAdd()">
              <div class="user_info_container">
                <img src="${___HTML_LOADER_IMPORT_4___}">
                <div class="name_account_container flex-column">
                  <span class="name" t="contacts.contacts_select_no_friends_yet"></span>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div> 
    </div>
  </section>
</template>`;
// Exports
export default code;